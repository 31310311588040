const linkResolver = () => doc => {
  switch (doc.type) {
    case 'homepage':
      return '/';
    case 'page':
      return `/${doc.uid}`;
    case 'faculty_member':
      return `/faculty/${doc.id}`;
    case 'graduate':
    case 'graduate-member':
      return `/graduate/${doc.uid}`;
    case 'event':
      return `/event/${doc.uid}`;
  }
};

module.exports = linkResolver;
