import React, { useEffect, useContext } from 'react';
import { usePrismicPreview } from 'gatsby-source-prismic';
import Spinner from '../../components/Spinner';
import { useStaticQuery, navigate, graphql } from 'gatsby';
import linkResolver from '../../utils/linkResolver';
import { PreviewContext } from '../../components/Preview';

const PreviewPage = () => {
  const { setValues } = useContext(PreviewContext);
  const { allSitePage } = useStaticQuery(graphql`
    {
      allSitePage {
        nodes {
          path
        }
      }
    }
  `);
  const allPaths = allSitePage.nodes.map(node => node.path);
  const { isPreview, previewData, path } = usePrismicPreview({
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    pathResolver: linkResolver,
  });
  useEffect(() => {
    if (isPreview === false || !previewData) return;
    setValues({ previewData });
    if (allPaths.some(_path => _path.includes(path))) {
      navigate(path);
    } else {
      navigate('/preview/unpublished');
    }
  }, [isPreview, previewData, path]);
  if (isPreview === false) {
    throw 'Not a preview!';
  }
  return <Spinner fullScreen />;
};

export default PreviewPage;
