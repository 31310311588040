import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { space, display } from 'styled-system';

const ripple = keyframes`
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
`;

const StyledSpinner = styled.div`
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  div {
    position: absolute;
    border: 4px solid ${({ theme }) => theme.colors.chestnutRose};
    opacity: 1;
    border-radius: 50%;
    animation: ${ripple} 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  div:nth-child(2) {
    animation-delay: -0.5s;
  }
  ${({ fullScreen }) =>
    fullScreen &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        position: static;
      }
    `}
  ${space}
  ${display}
`;

const Spinner = props => {
  return (
    <StyledSpinner {...props}>
      <div />
    </StyledSpinner>
  );
};

export default Spinner;
